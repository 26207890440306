/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useRef, useContext, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
// import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

import { Dialog, Avatar, MenuItem, Menu, Grid } from '@material-ui/core';
import { getAuth, RecaptchaVerifier, PhoneAuthProvider } from 'firebase/auth';
import MyInput from '../../components/input/MyInput';
import Button from '../../components/button/MyButton';
import LoginInform from './LoginInform';
import routing from '../../service/routes/routing';

import api from '../../service/axios/api';
import FlashContext from '../../components/contexts/FlashContext';
import './login.css';
import Loading from '../../components/loading/Loading';

const Login = function (props) {
  const query = new URLSearchParams(props.location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const [steep, setSteep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const refUser = useRef(null);
  const refCode = useRef(null);
  const refPassword = useRef(null);
  const [language, setLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [toFactor, setToFactor] = useState();
  const [userId, setUserId] = useState('');
  const [verificationId, setVerificationId] = useState();
  const [required2fa, setRequired2fa] = useState(false);
  const [typeLogin, setTypeLogin] = useState();
  const auth = getAuth();
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [loadingMsg, setLoadingMsg] = useState(t('Loading'));

  // let recaptchaVerifier = null;
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  const userLocale = navigator?.languages?.length
    ? navigator.languages[0]
    : navigator.language;

  // Ou da seguinte maneira 👇️
  // console.log('2', navigator.languages); //
  useEffect(() => {
    if (query) {
      const data = query.get('token');

      if (data) {
        api.mySession.set(data).then(() => {
          if (
            api.currentUser?.user?.role?.name === 'GerenteGM' ||
            api.currentUser?.user?.role?.name === 'GerenteLoja'
          ) {
            history.push(routing.fidelitydashboard);
          } else {
            history.push(routing.dashboard);
          }
        });
      }
    }
    api.language
      .get()
      .then(l => {
        if (l) {
          setLanguage(l);
        } else if (userLocale) {
          api.language.set(userLocale.substring(0, 2));
          setLanguage(userLocale.substring(0, 2));
          window.location.reload();
        } else {
          setLanguage('pt');
        }
      })
      .catch(() => {
        setLanguage('pt');
      });
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = lng => {
    api.language.set(lng);
    // i18n.changeLanguage(lng);
    window.location.reload();
    handleClose();
  };

  const { notifyError, notifySuccess } = useContext(FlashContext);

  // eslint-disable-next-line no-unused-vars

  // const initializeReca = () => {
  // eslint-disable-next-line new-cap

  // };

  // recaptchaVerifier.render()
  //   .then((widgetId) => {
  //     window.recaptchaWidgetId = widgetId;
  //   });
  // useEffect(() => {
  //   console.log(recaptchaVerifier);
  // }, [recaptchaVerifier]);

  useEffect(() => {
    // console.log('carregar captecha', required2fa);
    if (required2fa) {
      const recap = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: response => {
            console.log(response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
          },
        },
        auth,
      );
      setRecaptchaVerifier(recap);
    }
  }, [required2fa]);
  // eslint-disable-next-line consistent-return
  const handleNext = e => {
    if (e === 'SMS') {
      setTypeLogin('sms');
      setLoadingMsg(t('MessageSend'));
      setLoading(true);

      api.user
        .sen2Fa('sms', userId)
        .then(res => {
          setLoading(false);
          setMsg(e);
          setOpenModal(true);
        })
        .catch(err => {
          console.log(err);
        });

      // const phoneAuthProvider = new PhoneAuthProvider(auth);
      // phoneAuthProvider
      //   .verifyPhoneNumber(`+55${toFactor.phone}`, recaptchaVerifier)
      //   .then(verify => {
      //     setVerificationId(verify);
      //     setLoading(false);
      //     setMsg(e);
      //     setOpenModal(true);
      //   })
      //   .catch(error2 => {
      //     notifyError(error2.message);
      //     setLoading(false);
      //   });
    }
    if (e === 'e-mail') {
      setTypeLogin('email');
      setLoadingMsg(t('MessageSendEmail'));
      setLoading(true);
      api.user
        .sen2Fa('email', userId)
        .then(res => {
          console.log(res);
          setMsg(e);
          setOpenModal(true);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });

      // api.user
      //   .enterbyemail(email, pass)
      //   .then(res => {
      //     setLoading(false);
      //     notifySuccess(t('MessageSendEmailSuccess'));
      //   })
      //   .catch(error => {
      //     setLoading(false);
      //     // notifyError(t('MessageSendEmailError'));
      //     notifyError(error.message);
      //   });
    }
  };

  const handleEnter = () => {
    setLoadingMsg(t('Logging'));
    setLoading(true);
    api.user
      .verify2fa2(typeLogin, refCode.current.value, email, userId)
      .then(() => {
        setLoading(false);
        if (
          api.currentUser?.user?.role?.name === 'GerenteGM' ||
          api.currentUser?.user?.role?.name === 'GerenteLoja'
        ) {
          history.push(routing.fidelitydashboard);
        } else {
          history.push(routing.dashboard);
        }
      })
      .catch(error => {
        setLoading(false);
        notifyError(error.message);
      });
    // const phoneAuthCredential = PhoneAuthProvider.credential(verificationId,
    //  refCode.current.value);
    // const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
    // toFactor.resolver.resolveSignIn(multiFactorAssertion).then((userCredential) => {
    //   console.log('USER', userCredential);
    // })
    // .catch((error) => { console.log('ERROR FINAL', error); });
    //
  };

  const handleLogin = () => {
    setLoading(true);
    // api.post(
    //   'user/login',
    //   {
    //     email: refUser.current.value,
    //     password: refPassword.current.value,
    //   },
    // );
    setEmail(refUser.current.value);
    setPass(refPassword.current.value);
    api.user
      .login(refUser.current.value, refPassword.current.value)
      .then(res => {
        setUserId(res.data.userId);
        if (res.data.message === '2FA required') setRequired2fa(true);
        setToFactor(res.data.retorno);
        setSteep(steep + 1);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.problems) {
          if (error.problems.length > 0) {
            error.problems.forEach(p => {
              notifyError(p.msg);
            });
          }
        } else {
          notifyError(error?.message);
        }
      });
    // setSteep(steep + 1)
  };

  const enterPress = e => {
    if (e === 'Usuário') refPassword.current.focus();
    if (e === 'Senha') handleLogin();
    if (e === 'Código') handleEnter();
  };

  const forgotPassword = () => {
    history.push(routing.forgotPassword);
    // i18n.changeLanguage('pt');
  };

  const resend2FA = () => {
    console.log('Reenviar...', typeLogin);
    setSteep(steep - 1);
    setTypeLogin(typeLogin === 'email' ? 'sms' : 'email');
    setLoadingMsg(t('MessageSend'));
    setLoading(true);

    api.user
      .sen2Fa(typeLogin === 'email' ? 'sms' : 'email', userId)
      .then(res => {
        setLoading(false);
        setMsg(typeLogin === 'email' ? 'SMS' : 'Email');
        setOpenModal(true);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <>
      <div id="recaptcha-container" name="recaptcha-container" />
      <Loading open={loading} msg={loadingMsg} />
      <div className="base">
        <div className="lateral">
          <img src="/img/controlEcosystem.webp" alt="logo" className="logo" />
          <div className="textPosition">
            {steep === 0 && (
              <>
                <h4 className="textWelcome">
                  {t('Welcome')}
                  {/* Bem-vindo ao */}
                </h4>
                <h4 className="textWelcome"> Control EcoSystem!</h4>
                <h4 className="textWelcome">{t('Login')}</h4>
              </>
            )}
            {steep >= 1 && (
              <>
                {/* <p className="textWelcome" style={{ marginBottom: -30 }}>
                Bem-vindo ao
              </p> */}
                <h4 className="textWelcome">{t('Label2')}</h4>
                {/* <p className="textWelcome">
                Faça login para continuar.
              </p> */}
              </>
            )}
          </div>
        </div>
        <div className="baseLogin">
          {steep === 0 && (
            <div className="login1">
              <p className="textLogin">Login</p>
              <MyInput
                myRef={refUser}
                icon="user"
                label={t('User')}
                enterPress={enterPress}
              />
              <MyInput
                myRef={refPassword}
                type="password"
                icon="password"
                label={t('Password')}
                enterPress={enterPress}
              />

              <p
                className="textForgot"
                style={{ marginBottom: 100, cursor: 'pointer' }}
                onClick={forgotPassword}
                onKeyDown={forgotPassword}
              >
                {t('Forgot')}
              </p>
              <Button onClick={handleLogin} label={t('Continue')} />
              {/* <footer
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 0,
                  height: '2.5rem',
                  width: '100%',
                }}
              >
                <h6>Todos direitos reservados para Promotech</h6>
                <a href="https://www.google.com.br">DESENVOLVEDORES</a>
              </footer> */}
            </div>
          )}
          {steep === 1 && (
            <div className="login1">
              <p className="textLogin">{t('Label1')}</p>
              {/* {!required2fa && ( */}
              <Button
                style={{ marginBottom: 20, width: 592 }}
                onClick={() => handleNext('e-mail')}
                label="Email"
              />
              {/* // )} */}
              {/* <Button
                style={{ marginBottom: 20, width: 592 }}
                onClick={() => handleNext('WhatsApp')}
                label="WhatsApp"
              /> */}
              {/* {required2fa && ( */}
              <Button
                style={{ marginBottom: 20, width: 592 }}
                onClick={() => handleNext('SMS')}
                label="SMS"
              />
              {/* )} */}
              {/* <footer
                style={{
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: 0,
                  height: '2.5rem',
                  width: '100%',
                }}
              >
                <h6>Todos direitos reservados para Promotech</h6>
                <a
                  style={{ marginTop: '-20px' }}
                  href="https://www.google.com.br"
                >
                  DESENVOLVEDORES
                </a>
              </footer> */}
            </div>
          )}
          {steep === 2 && (
            <div className="login1">
              <h5 className="textLogin">{t('Label3')}:</h5>
              <MyInput
                myRef={refCode}
                type="password"
                icon="password"
                label={t('Code')}
                enterPress={enterPress}
              />
              <p>
                Reenviar código por {typeLogin === 'email' ? 'SMS' : 'E-mail'}?{' '}
                <span
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={resend2FA}
                >
                  Clique aqui
                </span>
              </p>
              <Button
                style={{ marginBottom: 20 }}
                onClick={handleEnter}
                label={t('Label4')}
              />
            </div>
          )}
          <footer
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: 10,
              height: '2.5rem',
              width: '100%',
              // marginTop: '100vh -20px',
              // left: 0,
            }}
          >
            <div>
              <h6 style={{ margin: '0px' }}>
                Todos direitos reservados para Promotech
              </h6>
              <a
                style={{ marginBottom: '20px' }}
                href="https://elementcontrol.azurewebsites.net"
                target="_blank"
                rel="noreferrer"
              >
                DESENVOLVEDORES
              </a>
            </div>
          </footer>
        </div>
        <Grid className="baseAvatar">
          {language === 'en' && (
            <Avatar
              alt="Remy Sharp"
              src="./img/eua.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          {language === 'es' && (
            <Avatar
              alt="Remy Sharp"
              src="./img/espanha.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          {language === 'pt' && (
            <Avatar
              alt="Remy Sharp"
              src="./img/brasil.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          {!language && (
            <Avatar
              alt="Remy Sharp"
              src="./img/brasil.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          <Avatar
            alt="Remy Sharp"
            src="./img/whats.webp"
            style={{ marginTop: 20, marginLeft: -60, cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send/?phone=551131810646&text&type=phone_number&app_absent=0',
                '_blank',
              )
            }
          />
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {language !== 'en' && (
            <MenuItem
              // className={classes.textNotification}
              onClick={() => handleLanguage('en')}
            >
              <Avatar
                alt="English"
                src="./img/eua.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              English
            </MenuItem>
          )}

          {language !== 'pt' && (
            <MenuItem
              // className={classes.textNotification}
              onClick={() => handleLanguage('pt')}
            >
              <Avatar
                alt="Portugues"
                src="./img/brasil.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              Português
            </MenuItem>
          )}

          {language !== 'es' && (
            <MenuItem
              // className={classes.textNotification}
              onClick={() => handleLanguage('es')}
            >
              <Avatar
                alt="Spanish"
                src="./img/espanha.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              Spanish
            </MenuItem>
          )}
        </Menu>
      </div>

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        {/* <Modal.Header>Teste</Modal.Header>
        <Modal.Body> */}
        <LoginInform
          msg={msg}
          setSteep={setSteep}
          steep={steep}
          setOpen={setOpenModal}
        />
        {/* </Modal.Body> */}
      </Dialog>
    </>
  );
};

export default Login;
